import { useState } from "react";
import { Classification } from "../types";
import { useJobIdContext } from "../JobIdContext";

interface ClassListResult {
  classes: Classification[];
  addClass: (classification: Classification) => void;
  removeClass: (index: number) => void;
  updateClass: (index: number, classification: Classification) => void;
}

const useClassList = (): ClassListResult => {
  const [classes, setClasses] = useState<Classification[]>([]);
  const { datasets, setDatasets, imageLoaded, setImageLoaded } =
  useJobIdContext();

  const addClass = (classification: Classification) => {
    setClasses((prevClasses) => [...prevClasses, classification]);
  };

  const removeClass = (index: number) => {
    setClasses((prevClasses) => prevClasses.filter((_, i) => i !== index));
    setDatasets((prevDatasets: any[]) => prevDatasets.filter((_, i) => i !== index));
  };

  const updateClass = (index: number, newClassification: Classification) => {
    setClasses((prevClasses) =>
      prevClasses.map((classification, i) =>
        i === index ? newClassification : classification
      )
    );
    setDatasets((prevDatasets: any[]) => prevDatasets.filter((_, i) => i !== index));
    setDatasets((prevDatasets: any[]) =>
      prevDatasets.map((dataset, i) =>
        i === index ? { ...dataset, name: newClassification.name } : dataset
      )
    );
  };

  return {
    classes,
    addClass,
    removeClass,
    updateClass,
  };
};

export default useClassList;
