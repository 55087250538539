import React, { useState, useRef, useEffect } from 'react';
import { Container, Image, API_URL, ContainerBody, ContainerHeader, Button, Text, Row, SectionLabel, Colors, Spacer } from '../utility/styles';
import useImageUploader from '../hooks/useImageUploader';
import { useJobIdContext } from '../JobIdContext';
import styled from 'styled-components';
import { ArrowCounterClockwise, ArrowCycle, Camera, Cross } from 'akar-icons';
import OutputSection from './OutputSection';

const DropZone = styled.div`
  height: 200px;
  border: 2px dashed ${Colors.light3};
  color: ${Colors.light3};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 12px 0px;
  &:hover {
    border: 2px dashed ${Colors.light2};
    color: ${Colors.light2};
  }
  @media screen and (max-width: 980px) {
    min-height: 80px;
    height: 30vh;
  }
`;

const CameraContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  @media screen and (max-width: 980px) {
    display: none;
  }
`;

const Video = styled.video`
  width: 100%;
  object-fit: cover;
  margin: 12px 0px;
  border-radius: 6px;
`;

const SelectedImage = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 12px 0px;
  border-radius: 6px;
`;

const CameraActionsContainerTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 24px 16px;
  position: absolute;
  z-index: 1;
  width: CALC(100% - 28px);
  @media screen and (max-width: 980px) {
    justify-content: space-between;
  }
`;

const CameraActionsContainerBottom = styled(CameraActionsContainerTop)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  justify-content: center;
`;

const OpenCameraButton = styled(Button)`
  @media screen and (max-width: 980px) {
    display: none;
  }
`;


const CaptureButton = styled(Button)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 8px;
  background-color: ${Colors.light1};
  border: 2px solid ${Colors.light3};
  outline: 2px solid ${Colors.light1};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${Colors.light0};
  }
`;

const ImageInput: React.FC = (): JSX.Element => {
  const { error, uploadProgress, uploadImage } = useImageUploader();
  const { jobId, selectedImage, setSelectedImage, predictionResult, isLoadingPrediction, setPredictionResult } = useJobIdContext();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const openCamera = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: { } })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        })
        .catch((error) => {
          console.error('Error accessing camera:', error);
        });
    } else {
      console.error('getUserMedia is not supported');
    }
  };

  const handleOpenCamera = () => {
    setIsCameraOpen(true);
    setSelectedImage(null);
    openCamera();
  };

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject as MediaStream;
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      videoRef.current.srcObject = null;
    }
  };


  useEffect(() => {
    if (isCameraOpen) {
      openCamera();
    }
  }, []);

  const handleCapture = () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const context = canvas.getContext('2d');

      if (context) {
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          if (blob) {
            setSelectedImage(new File([blob], 'captured-image.png'));
            handleCloseCamera();
          }
        }, 'image/png');
      }
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedImage(event.target.files[0]);
    }
  };

  const handleImageUpload = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (selectedImage) {
      const url = API_URL + '/predict_from_image';
      uploadImage(selectedImage, url, jobId)
        .then((prediction) => {
          if (prediction) {
            console.log('Image uploaded successfully');
            console.log('Prediction:', prediction);
            setPredictionResult(prediction);
            // Handle the prediction value as needed
          } else {
            console.error('Image upload failed');
            // Handle the error case
          }
        })
        .catch((error) => {
          console.error('Error uploading image:', error);
          // Handle the error case
        });
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      setSelectedImage(event.dataTransfer.files[0]);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <Container>
      <ContainerHeader>
        <Row>
          <SectionLabel> 2 </SectionLabel>
          <Text color="secondary"> Test your model </Text>
        </Row>
        <Row>

        </Row>
      </ContainerHeader>
      <ContainerBody>
        {!selectedImage&& 
          isCameraOpen ? (
            <CameraContainer>
              <CameraActionsContainerTop>
                <Button onClick={handleCloseCamera}>
                  <Cross strokeWidth={2} size={16} />
                </Button>
              </CameraActionsContainerTop>
              <Video ref={videoRef} autoPlay playsInline />
              <CameraActionsContainerBottom>
                <CaptureButton onClick={handleCapture} disabled={isLoadingPrediction}>
                </CaptureButton>
              </CameraActionsContainerBottom>
            </CameraContainer>
          ) : !selectedImage&& (
            <>
              <OpenCameraButton onClick={handleOpenCamera}> Open camera</OpenCameraButton>
              <DropZone
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onClick={() => document.getElementById('image-input')?.click()}
              >
                + add image
              </DropZone>
              <input
                id="image-input"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
            </>
          )
          }
        {selectedImage && (
          <CameraContainer>
            <CameraActionsContainerTop>
                <Button onClick={() => setSelectedImage(null)}>
                  <Cross strokeWidth={2} size={16} />
                </Button>
            </CameraActionsContainerTop>
            <SelectedImage src={URL.createObjectURL(selectedImage)} alt="Selected" />
          </CameraContainer>
        )}
        <Button onClick={handleImageUpload} disabled={isLoadingPrediction || !selectedImage || !jobId}>
          Upload
        </Button>
        {error && <p>Error: {error}</p>}
        {uploadProgress > 0 && uploadProgress !== 100 && <p>Upload Progress: {uploadProgress}%</p>}
        {/* {isLoadingPrediction && <p>Calculating prediction...</p>} */}
      </ContainerBody>
      <br/>
      {predictionResult &&
              <>
                <OutputSection/>
              </>
            }
    </Container>
  );
};

export default ImageInput;
