import React, { createContext, useState, useContext, ReactNode } from 'react';

interface JobIdContextProps {
  // datasets: { [key: string]: string };
  // setDatasets: (ids: { [key: string]: string }) => void;
  datasets: any;
  setDatasets: any;
  imageLoaded: number;
  setImageLoaded: (loaded: number) => void;
  jobId: string;
  setJobId: (id: string) => void;
  classificationStatus: string | null;
  setClassificationStatus: (status: string | null) => void;
  selectedImage: File | null;
  setSelectedImage: (image: File | null) => void;
  isLoadingPrediction: boolean;
  setIsLoadingPrediction: (isLoading: boolean) => void;
  predictionResult: string;
  setPredictionResult: (result: string) => void;

}

const JobIdContext = createContext<JobIdContextProps>({
  datasets: [{ id: '' }],
  setDatasets: () => [{ id: ''}],
  imageLoaded: 0,
  setImageLoaded: () => { },
  jobId: '',
  setJobId: () => { },
  classificationStatus: '',
  setClassificationStatus: () => { },
  selectedImage: new File([], ''),
  setSelectedImage: () => { },
  isLoadingPrediction: false,
  setIsLoadingPrediction: () => { },
  predictionResult: '',
  setPredictionResult: () => { },
});

export const JobIdProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [datasets, setDatasets] = useState<Array<{ id: string }>>([]);
  const [imageLoaded, setImageLoaded] = useState(0);
  const [jobId, setJobId] = useState('');
  const [classificationStatus, setClassificationStatus] = React.useState<string | null>(null);
  const [predictionResult, setPredictionResult] = useState('');
  const [isLoadingPrediction, setIsLoadingPrediction] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null)

  return (
    <JobIdContext.Provider value={{
      datasets,
      setDatasets,
      imageLoaded,
      setImageLoaded,
      jobId,
      setJobId, 
      classificationStatus, 
      setClassificationStatus, 
      selectedImage, 
      setSelectedImage,
      isLoadingPrediction,
      setIsLoadingPrediction, 
      predictionResult, 
      setPredictionResult
    }}>
      {children}
    </JobIdContext.Provider>
  );
};

export const useJobIdContext = (): JobIdContextProps => {
  return useContext(JobIdContext);
};