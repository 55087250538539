import { useState } from 'react';
import axios, { AxiosProgressEvent } from 'axios';
import { useJobIdContext } from '../JobIdContext';

interface UploadResult {
  isLoadingPrediction: boolean;
  error: string | null;
  uploadProgress: number;
  uploadImage: (imageFile: File, url: string, classifierId: string) => Promise<string | null>;
}

const useImageUploader = (): UploadResult => {
  
  const { isLoadingPrediction, setIsLoadingPrediction} = useJobIdContext();
  // const [isLoadingPrediction, setIsLoadingPrediction] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const uploadImage = async (imageFile: File, url: string, classifierId: string): Promise<string | null> => {
    setIsLoadingPrediction(true);
    setError(null);
    setUploadProgress(0);

    try {
      const formData = new FormData();
      formData.append('image', imageFile);
      formData.append('classifier_id', classifierId);

      const response = await axios.post(url, formData, {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (progressEvent.total) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          }
        },
      });

      setIsLoadingPrediction(false);
      console.log('Image uploaded successfully');
      console.log('Prediction:', response.data.prediction);
      return response.data.prediction;
    } catch (error: any) {
      setIsLoadingPrediction(false);
      setError(error.message || error.toString());
      return null;
    }
  };

  return {
    isLoadingPrediction,
    error,
    uploadProgress,
    uploadImage,
  };
};

export default useImageUploader;
