import styled from 'styled-components';
import { Colors } from "../utility/styles";

const ToggleContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 29px;
  height: 14px;
`;

const ToggleSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Colors.dark3};
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;

  :before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 0px;
    bottom: 0px;
    background-color: ${Colors.light2};
    -webkit-transition: .2s;
    transition: .2s;
    border-radius: 50%;
  }
`;

const ToggleCheckbox = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  :checked + ${ToggleSlider} {
    background-color: ${Colors.dark3};
  }
  :focus + ${ToggleSlider} {
    box-shadow: 0 0 0px #2196F3;
  }
  :checked + ${ToggleSlider}:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
`;

interface ToggleProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

export const Toggle: React.FC<ToggleProps> = (props) => {
  const { value, onChange } = props;
  return (
    <ToggleContainer>
      <ToggleCheckbox 
        type='checkbox' 
        checked={value} 
        onChange={event => onChange(event.target.checked)}/>
      <ToggleSlider></ToggleSlider>
    </ToggleContainer>
  );
};
