import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import {
  API_URL,
  Button,
  Container,
  ContainerBody,
  ContainerFooter,
  ContainerHeader,
  Text,
  Row,
  SectionLabel,
  TextInput,
  Column,
  SmallText,
  Spacer,
} from "../utility/styles";
import { Toggle } from "../components/Toggle";
import useClassList from "../hooks/useClassList";
import { useJobIdContext } from "../JobIdContext";
import DatasetSection from "./DatasetSection";
import styled from "styled-components";

const ToggleContainer = styled(Row)`
  @media (max-width: 980px) {
    display: none;
  }
`;

const ClassInput = (): JSX.Element => {
  const { classes, addClass, removeClass, updateClass } = useClassList();
  const [inputValue, setInputValue] = useState("");
  const [datasetLoadingStatus, setDatasetLoadingStatus] = useState({});
  const { datasets, jobId, setJobId, setDatasets } = useJobIdContext();
  const [enableOtherClass, setEnableOtherClass] = useState(true);

  const postClassNamesMutation = useMutation(
    async (finalDatasets) => {
    console.log("Generating model");
      // If finalDatasets isn't at least 2 classes, fail.
      if (Array.isArray(finalDatasets)) {
        if (finalDatasets.length < 2) {
          throw new Error("Must have at least 2 classes to create a model.");
        }
      }

      const data = {
        datasets: finalDatasets,
      };
      console.log(data)
  
      const response = await axios.post(API_URL + "/classifiers", data);
  
      return response.data;
    },
    {
      onSuccess: (data) => {
        setJobId(data.id.toString());
        console.log("Job ID:", data.id.toString());
      },
      onError: (error: any) => {
        console.error("Error posting class names:", error);
      },
    }
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleAddClassName = async () => {
    if (inputValue) {
      const newClassification = {
        name: inputValue,
        prompt: "a photo of " + inputValue,
      };
      addClass(newClassification);
      setInputValue("");
    }
  };

  const handleRemoveClassName = (index: number) => {
    removeClass(index);
  };

  const handlePostData = () => {
    let finalDatasets = datasets;
    
    if(enableOtherClass) {
      const otherDataset = {id: "00000000-0000-0000-0000-000000000000", /* any other properties your datasets need */ };
      finalDatasets = [...datasets, otherDataset];
    }
    
    postClassNamesMutation.mutate(finalDatasets);
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleAddClassName();
  };

  const isAnyDatasetLoading = Object.values(datasetLoadingStatus).some(
    (isLoading) => isLoading
  );
  const totalClasses = classes?.length + (enableOtherClass ? 1 : 0);
  const canGenerateModel = (totalClasses >= 2) && !isAnyDatasetLoading;

  const handleDatasetLoading = (index: number) => (isLoading: boolean) => {
    console.log("Setting dataset loading status for index", index, "to", isLoading);
    setDatasetLoadingStatus(prevStatus => ({ ...prevStatus, [index]: isLoading }));
    console.log("isAnyDatasetLoading:", isAnyDatasetLoading);
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      const input = inputRef.current;

      if (container && input) {
        const containerRect = container.getBoundingClientRect();
        const inputRect = input.getBoundingClientRect();

        if (inputRect.right > containerRect.right) {
          input.classList.add("sticky-input");
        } else {
          input.classList.remove("sticky-input");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (canGenerateModel) {
      handlePostData();
    }
    else {
      setJobId("");
    }
  }, [canGenerateModel, enableOtherClass]);

  useEffect(() => {
    console.log("Datasets: ", datasets);
    console.log("Classes: ", classes);
  }, [datasets, classes]);
  

  return (
    <>
      <Container ref={containerRef}>
        <ContainerHeader>
          <Row>
            <SectionLabel> 1</SectionLabel>
            <Text color="secondary"> Build your model </Text>
          </Row>
          <Row>
            <ToggleContainer>
              <SmallText color="secondary">include "other" class</SmallText>
              <Spacer />
              <Spacer />
              <Toggle value={enableOtherClass} onChange={setEnableOtherClass} />
            </ToggleContainer>
          </Row>
        </ContainerHeader>
        <ContainerBody>
          {classes?.length === 0 ? null : (
            <>
              {classes.map((classification, index) => (
                <DatasetSection
                  key={index}
                  index={index}
                  classification={classification}
                  handleRemoveClassName={handleRemoveClassName}
                  updateClass={updateClass}
                  handleDatasetLoading={handleDatasetLoading(index)}
                />
              ))}
            </>
          )}
        </ContainerBody>
      </Container>
      <form onSubmit={handleFormSubmit}>
        <ContainerFooter>
          <TextInput
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={
              classes?.length === 0
                ? 'Add your first class name... e.g. "dog" or "green can of LaCroix" '
                : "Add another class name"
            }
            autoFocus
          />
          {inputValue && <Button type="submit">Add</Button>}
        </ContainerFooter>
      </form>
    </>
  );
};

export default ClassInput;
