import { useState, useRef, ChangeEvent, FormEvent, useEffect } from "react";
import styled from "styled-components";
import { Colors } from "../utility/styles";
import { Pencil } from 'akar-icons';

const Container = styled.label`
  width: fit-content;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  margin: 5px 0px;
  background-color: ${Colors.dark2};
  border-radius: 8px;
  position: relative;
  :hover {
    background-color: ${Colors.dark3};
  }
`;

const Label = styled.span`
  opacity: 0.5;
  font-weight: 200;
  @media screen and (max-width: 980px) {
    display: none;
  }
`;

const Input = styled.input`
  margin-left: 5px;
  border: none;
  background: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  padding: 0;
  outline: none;
  width: auto;
`;

const PencilIcon = styled(Pencil)`
  position: absolute;
  right: 8px;
  opacity: 0;
  background-color: ${Colors.dark3};
  height: 16px;
  width: 16px;
  // transition: opacity 0.2s ease-in-out;
  ${Container}:hover & {
    opacity: 1;
  }
`;

type Props = {
  label: string;
  initialValue: string;
  onSubmit: (value: string) => void;
};

const PillInput = ({ label, initialValue, onSubmit }: Props) => {
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(value);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = `${inputRef.current.value.length + 1}ch`;
    }
  }, [value]);

  return (
    <Container>
      <Label>{label}: </Label>
      <form onSubmit={handleSubmit}>
        <Input
          ref={inputRef}
          value={value}
          onChange={handleChange}
        />
      </form>
      <PencilIcon size={16} />
    </Container>
  );
};

export default PillInput;
