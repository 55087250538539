import styled from 'styled-components';

export const Colors = {
    background: 'rgba(0, 0, 0, 0.1)',
    dark0: '#111111',
    dark1: '#131313',
    dark2: '#252525',
    dark3: '#333333',
    light0: '#FFFFFF',
    light1: '#CECECE',
    light2: '#9C9C9C',
    light3: '#6A6A6A',
    red0: '#310A0A',

};

export const Sizes = {
    xsmall: '4px',
    small: '8px',
    medium: '12px',
    large: '16px',
    xlarge: '24px',
    xxlarge: '32px',
};

export const Spacer = styled.div`
    height: 6px;
    width: 6px;
    `;

export const Text = styled.div`
    color: ${props => props.color === "secondary" ? Colors.light2 : Colors.light1}; 
    font-size: 16px; 
    font-weight: 400;
    line-height: 1.3;
    `;

export const SmallText = styled(Text)`
    font-size: 13px;
    `;
export const SectionLabel = styled(Text)`
    margin-right: 6px;
    `;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    `;    

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    `;    

export const Button = styled.button`
    background-color: ${Colors.dark2};
    color: ${Colors.light2};
    border: none;
    border-radius: 6px;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    cursor: pointer;
    // transition: 0.3s ease all;
    display: flex;
    align-items: center;
    &:hover {
        background-color: ${Colors.dark3};
        color: ${Colors.light1};
    }
    &:disabled {
        // background-color: ${Colors.dark3};
        // color: ${Colors.light1};
        cursor: not-allowed;
        opacity: 0.5;
    }
    &:disabled:hover {
        background-color: ${Colors.dark2};
         color: ${Colors.light2};

    `;

export const TextInput = styled.input`
    width: 100%;
    background-color: ${Colors.dark1};
    color: ${Colors.light1};
    border: none;
    border-radius: 6px;
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    cursor: pointer;
    // transition: 0.3s ease all;
    &:focus {
        outline: none;
        bodrer: none;
    }
    &::placeholder {
        color: ${Colors.light3};
    }
    &:hover::placeholder {
        color: ${Colors.light2};
        opacity: 0.8;
    `;

export const Container = styled.div`
    background-color: ${Colors.dark1};
    // height: 100%;
    // padding: 24px;
    // margin-top: 24px;
    // margin-bottom: 48px;
`;

export const ContainerBody = styled.div`
    padding: 20px 28px;
    overflow-y: scroll;
    @media (max-width: 980px) {
        padding: 16px 16px;
`;

export const ContainerHeader = styled(ContainerBody)`
    position: sticky;
    top: 0px;
    background-color: ${Colors.dark1};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
`;

export const ContainerFooter = styled(ContainerHeader)`
    position: sticky;
    bottom: 0px;
    left; 0px;
`;


export const Image = styled.img`
    width: 100%;
    // max-width: 400px;
`;

export const API_URL = 'https://luq6q8jul2.execute-api.us-west-2.amazonaws.com/staging'
