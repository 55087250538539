import { Container, ContainerBody } from '../utility/styles';
import { useJobIdContext } from '../JobIdContext';


const PredictionOutput = (): JSX.Element => {
  const { isLoadingPrediction, predictionResult } = useJobIdContext();
  return (
    <Container>
      <ContainerBody>
        {isLoadingPrediction ?
          <p>Calculating prediction...</p>
          :       predictionResult &&
            <>
              <h1>Prediction: {predictionResult}</h1>
            </>
        }
      </ContainerBody>
    </Container>
  );
};

export default PredictionOutput;
