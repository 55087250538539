import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useMutation } from "react-query";
import { Classification } from "../types";
import { API_URL, Colors, Column, Container, Row, Text } from "../utility/styles";
import { useJobIdContext } from "../JobIdContext";
import PillInput from "./PillInput";
import { TrashCan, Cross } from 'akar-icons';

interface ClassInputProps {
  classification: Classification;
  index: number;
  handleRemoveClassName: (index: number) => void;
  handleDatasetLoading: (isLoading: boolean) => void;
  updateClass: (index: number, newClassification: Classification) => void;
}

const ClassColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 28px;
  `;    


const ClassContainer = styled(Row)`
  margin: 8px 0;
  position: relative;
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;


const ImageContainer = styled.div`
  display: flex;
  flex-direction: reverse;
  flex-wrap: wrap;
  width: 100%;
  min-width: 60px;
  overflow-y: scroll;
  @media screen and (max-width: 980px) {
    max-height: 200px;
  }
`;

const EnlargedImageContainer = styled.div`
  position: fixed;
  border: 1px solid #eee;
  background-color: white;
  padding: 2px;
  z-index: 1000;
  pointer-events: none; // Ensures the mouseover events are not blocked
`;

const Image = styled.img`
  width: 3vw;
  height: 3vw;
  max-width: 54px;
  min-width: 24px;
  max-height: 54px;
  min-height: 24px;
  object-fit: cover;
  border-radius: 2.5px;
  margin: 2px;
`;

const DeletButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 1;
  color: ${Colors.light0};
  cursor: pointer;
`;

const ClassInput = (props: ClassInputProps): JSX.Element => {
  const { datasets, setDatasets, imageLoaded, setImageLoaded } =
    useJobIdContext();
  const [datasetId, setDatasetId] = useState<string | null>(null);
  const [datasetInfo, setDatasetInfo] = useState<any | null>(null);
  const [images, setImages] = useState<any[] | null>([]);
  const [isLoadingImages, setIsLoadingImages] = useState<boolean>(true);
  const [showDelete, setShowDelete] = useState(false);
  const [timer, setTimer] = useState<number>(0);
  // Hover and enlarge image
  const [hoveredImage, setHoveredImage] = useState<string | null>(null);
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [showEnlargedImage, setShowEnlargedImage] = useState<boolean>(false);
  const [hoverTimeout, setHoverTimeout] = useState<number | null>(null);

  const handleMouseEnter = (image: string) => {
    const timeout = window.setTimeout(() => {
      setHoveredImage(image);
      setShowEnlargedImage(true);
    }, 300); // 300ms delay
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
    }
    setShowEnlargedImage(false);
    setHoveredImage('');
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    setMousePosition({ x: e.clientX + 20, y: e.clientY + 20 });
  };

  useEffect(() => {
    return () => {
      if (hoverTimeout) {
        clearTimeout(hoverTimeout);
      }
    };
  }, [hoverTimeout]);

  // Image loading logic
  const handleSetLoadingImages = (isLoading: boolean) => {
    setIsLoadingImages(isLoading);
    props.handleDatasetLoading(isLoading);
  };

  const createDatasetMutation = useMutation(
    async (datasetInfo: { name: string; prompt: string }) => {
      // console.log("Sending POST to /datasets with data:", datasetInfo);
      const response = await axios.post(
        API_URL + "/datasets",
        datasetInfo
      );
      console.log("Response from /datasets:", response.data);
      setDatasetId(response.data.id);
      setDatasets((datasetIds: Array<{ id: string }>) => [
        ...datasets,
        response.data,
      ]);

      // console.log("Datasets:", datasets);

      return { datasetId: response.data.id };
    }
  );

  const fetchDatasetInfo = async (id: string | null) => {
    try {
      const response = await axios.get(`${API_URL}/datasets/${id}`);
      console.log("Dataset info:", response.data);
      setDatasetInfo(response.data);
    } catch (error) {
      // console.error("Failed to fetch dataset info:", error);
    }
  };

  useEffect(() => {
    if (isLoadingImages === true) {
      const pollInterval = setInterval(async () => {
        if (datasetId === null) {
          return;
        }

        fetchDatasetInfo(datasetId);
        if (
          datasetInfo !== null &&
          datasetInfo !== undefined &&
          datasetInfo.dataset.length === 0
        ) {
          handleSetLoadingImages(true);
        }
        if (
          datasetInfo !== null &&
          datasetInfo !== undefined &&
          datasetInfo.dataset.length > 0
        ) {
          setImages(datasetInfo.dataset);
          handleSetLoadingImages(false);
          setImageLoaded(imageLoaded + 1);
        }
      }, 1000);

      return () => {
        clearInterval(pollInterval);
      };
    }
  }, [datasetId, datasetInfo]);

  useEffect(() => {
    const fetchDatasetId = async () => {
      handleSetLoadingImages(true);
      const createDatasetResponse = await createDatasetMutation.mutateAsync(
        props.classification
      );
      const datasetId = createDatasetResponse.datasetId;
      setDatasetId(datasetId);
    };

    setImages([]);
    setDatasetInfo(null);
    setIsLoadingImages(true);
    setTimer(0);
    fetchDatasetId();
  }, [props.classification]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const updateClassName = (newName: string) => {
    props.updateClass(props.index, {
      ...props.classification,
      name: newName,
    });
  };

  const updateClassPrompt = (newPrompt: string) => {
    props.updateClass(props.index, {
      ...props.classification,
      prompt: newPrompt,
    });
  };

  return (
    <Container>
      <ClassContainer
        onMouseLeave={() => setShowDelete(false)}
        onMouseEnter={() => setShowDelete(true)}
      >
        <ClassColumn>
            <PillInput
              label="class"
              initialValue={props.classification.name}
              onSubmit={updateClassName}
            />
            <PillInput
              label="prompt"
              initialValue={props.classification.prompt}
              onSubmit={updateClassPrompt}
            />
        </ClassColumn>
        <ImageContainer onMouseMove={handleMouseMove}>
          {isLoadingImages ? (
            <Text color="secondary">calculating features... {timer}s</Text>
          ) : (
            <>
              {images &&
                images.map((image) => (
                  <Image
                    key={image}
                    src={image}
                    alt={image}
                    onMouseEnter={() => handleMouseEnter(image)}
                    onMouseLeave={handleMouseLeave}
                    onError={(e) => {
                      if (e.target instanceof HTMLElement) {
                        e.target.style.display = "none";
                      }
                    }}
                  />
                ))}
            </>
          )}
        </ImageContainer>
        {showEnlargedImage && hoveredImage && (
          <EnlargedImageContainer style={{ left: mousePosition.x, top: mousePosition.y }}>
            <img src={hoveredImage} alt="Enlarged view" style={{ maxWidth: '200px' }} />
          </EnlargedImageContainer>
        )}
        {showDelete && (
          <DeletButton onClick={() => props.handleRemoveClassName(props.index)}>
            <Cross strokeWidth={2} size={16} />
          </DeletButton>
        )}
      </ClassContainer>
    </Container>
  );
};

export default ClassInput;
