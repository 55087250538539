import "./index.css";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components"
import { useJobIdContext } from "./JobIdContext";

import InputSection from "./components/InputSection";
import SetupSection from "./components/SetupSection";
import OutputSection from "./components/OutputSection";
import {  Colors, Text } from "./utility/styles";
import DocsSection from "./components/DocsSection";
import { Analytics } from '@vercel/analytics/react';

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 980px) {
    display: block;
    // accounts for footer moving to top on mobile
    margin-top: 36px;
  }
`

interface PaneProps {
  windowHeight: number;
}


const Pane = styled.div<PaneProps>`
  flex-grow: 1;
  flex-basis: 0;
  float: left;
  margin: 24px;
  border-radius: 12px;
  background-color: ${Colors.dark1};
  height: CALC(100vh - 48px - 32px);
  max-height: CALC(100vh - 48px - 32px);
  overflow-y: scroll;
  transition: 0.3s ease all;
  @media screen and (max-width: 980px) {
    width: CALC(100% - 12px);
    margin: 6px;
    height: auto;
    height: ${props => `calc(${props.windowHeight}px / 2 - 30px)`};
  }
`;

const LeftPane = styled(Pane)`
  float: left;
`;

const RightPane = styled(Pane)`
  max-width: 30%;
  max-width: 450px;
  float: right;
  margin-left: 0px;
  @media screen and (max-width: 980px) {
    max-width: CALC(100% - 12px);
    margin: 6px;
    margin-top: 0px;
    // height: auto;
  }
`;

const UpperRightPane = styled.div`
  height: 50%;
  overflow: auto;
`;

const LowerRightPane = styled.div`
  height: 50%;
  overflow: auto;
`;

const Footer = styled.div`
position: fixed;
width: 100%;
bottom: 0px;
right: 0px;
background-color: black;
display: flex;
flex-direction: row;
justify-content: end;
align-items: center;
z-index: 100;
padding: 20px 24px;
@media screen and (max-width: 980px) {
  top: 0px;
  left: 0px;
  padding: 12px;
  bottom: auto;
  justify-content: center;
}
`;

const FooterItem = styled.a`
font-size: 14px;
color: ${Colors.light2};
margin-right: 24px;
cursor: pointer;
&:hover {
  color: ${Colors.light1};
}
&:focus {
  border: none;
  outline: none;
}
`;

export default function App() {
  const { datasets, imageLoaded, jobId, predictionResult } = useJobIdContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [datasets, imageLoaded]);
  
  return (
    <>
      <PageContainer>
        <LeftPane ref={containerRef} windowHeight={windowHeight}>
          <SetupSection />
        </LeftPane>
        <RightPane windowHeight={windowHeight}>
          <>
            <InputSection />
          </>
          <DocsSection />
        </RightPane>
      </PageContainer>
      <Footer>
        <FooterItem href="http://usezeroshot.com/" target="_blank"> About </FooterItem>
        <FooterItem href="https://github.com/moonshinelabs-ai/zeroshot-python" target="_blank"> Github </FooterItem>
        {/* <FooterItem href="start.usezeroshot.com/" target="_blank"> Examples </FooterItem> */}
        {/* <FooterItem href="start.usezeroshot.com/" target="_blank"> About </FooterItem> */}
      </Footer>
      <Analytics />
    </>
  );
}
