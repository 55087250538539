import { useEffect } from "react";
import { Container, Column, Image, API_URL, ContainerBody, ContainerHeader, Button, Row, Text, SectionLabel, Colors, Spacer } from '../utility/styles';
import { useJobIdContext } from '../JobIdContext';
import axios from "axios";
import styled from 'styled-components';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const DocsColumn = styled(Column)`
    margin: 0px 0px 24px 0px;
    position: relative;
    display: flex;
    justify-content: space-between;
`;


const BodyText = styled.div`
    color: ${Colors.light2};
    font-size: 13px;
    `;

const Code = styled(SyntaxHighlighter)`
    background-color: rgba(255,255,255,0.1);
    background-color: ${Colors.dark2};
    color: ${Colors.light1};
    padding: 8px;
    border-radius: 6px;
    font-size: 14px;
    margin: 12px 0;
    white-space: pre-wrap;
`;




const PredictionOutput = (): JSX.Element => {
    useEffect(() => {
      // This code acts to keep the Lambda function warm. When the user loads the page, we
      // call the keep warm endpoint for both datasets and classifiers. This will ensure
      // that the Lambda function is ready to go when the user wants to create a new dataset
      // or classifier.

      // Define the payload for POST requests
      const payload = {
        keep_warm: 0
      };

      // GET requests
      axios.get(`${API_URL}/datasets/keep_warm`)
        .then(response => console.log('GET datasets/keep_warm response:', response))
        .catch(error => console.error('Error with GET datasets/keep_warm:', error));

      axios.get(`${API_URL}/classifiers/keep_warm`)
        .then(response => console.log('GET classifiers/keep_warm response:', response))
        .catch(error => console.error('Error with GET classifiers/keep_warm:', error));

      // POST requests
      axios.post(`${API_URL}/datasets`, payload)
        .then(response => console.log('POST datasets response:', response))
        .catch(error => console.error('Error with POST datasets:', error));

      axios.post(`${API_URL}/classifiers`, payload)
        .then(response => console.log('POST classifiers response:', response))
        .catch(error => console.error('Error with POST classifiers:', error));

      axios.post(`${API_URL}/predict_from_image`, payload)
        .then(response => console.log('POST classifiers response:', response))
        .catch(error => console.error('Error with POST classifiers:', error));

  }, []);

  const { isLoadingPrediction, predictionResult, jobId } = useJobIdContext();
  return (
    <Container>
      <ContainerHeader>
        <Row>
          <SectionLabel> 3 </SectionLabel>
          <Text color="secondary"> Deploy your model </Text>
        </Row>
      </ContainerHeader>
      <ContainerBody>
        <DocsColumn>
            
            Use it with Python
            
        </DocsColumn>
        <DocsColumn>

            <BodyText>1. Install Zeroshot</BodyText>
            <Code language="python" style={darcula}>pip install zeroshot</Code>
        </DocsColumn>
        <DocsColumn>
            <BodyText>2. Define your classifier, using the model you just created </BodyText>
            <Code language="python" style={darcula}>
                {[
                    `model = zeroshot.Classifier(`,
                    `    "${jobId}"`,
                    `)`
                ].join('\n')}
            </Code>
        </DocsColumn>
        <DocsColumn>
            <BodyText>3. That's it! Just call the model </BodyText>
            <Code language="python" style={darcula}>
              {[
                `dog_url = "https://tinyurl.com/3y7pkzxj"`,
                "",
                "result = model.predict(dog_url)",
                "class_name = model.classes[result]",
                "",
                `print(f'Prediction: "{class_name}"')`
              ].join('\n')}
            </Code>
        </DocsColumn>
        <DocsColumn>
            <BodyText>
                4. You can also
                <a href={`${API_URL}/downloads/classifiers/${jobId}`}> download the model </a> 
                and load the file, or <a href={`zeroshot://${jobId}`}>open with the app.</a>
            </BodyText>
            <Code language="python" style={darcula}>
                {[
                    `model = zeroshot.Classifier(`,
                    `    "/path/to/model"`,
                    `)`
                ].join('\n')}
            </Code>
        </DocsColumn>
        <DocsColumn>
            <BodyText>It's that simple. Try it in our notebook
                <br/> <br/>
                <a target="_blank" href="https://colab.research.google.com/github/moonshinelabs-ai/zeroshot-docs/blob/main/examples/run_classification.ipynb">
                    <img src="https://colab.research.google.com/assets/colab-badge.svg" alt="Open In Colab"/>
                </a>
                </BodyText>
        </DocsColumn>
      </ContainerBody>
    </Container>
  );
};

export default PredictionOutput;
